import react, {useEffect, useState} from 'react';
import './App.css';


function App() {
  const [answerOne, setAnswerOne] = useState();
  const handleAnswerOneChange = (event) => {
    setAnswerOne(event.target.value);
  };

  const [answerOneValue, setAnswerOneValue] = useState(50);
  const handleSliderOneChange = (event) => {
    setAnswerOneValue(event.target.value);
  };

  const [answerTwo, setAnswerTwo] = useState();
  const handleAnswerTwoChange = (event) => {
    setAnswerTwo(event.target.value);
  };

  const [answerTwoSliderValue, setAnswerTwoSliderValue] = useState(0);
  const handleSliderTwoChange = (event) => {
    setAnswerTwoSliderValue(event.target.value);
  };

  const [answerTwoA, setAnswerTwoA] = useState();
  const handleAnswerTwoAChange = (event) => {
    setAnswerTwoA(event.target.value);
  }

  const [answerThree, setAnswerThree] = useState();
  const handleAnswerThreeChange = (event) => {
    setAnswerThree(event.target.value);
  };

  const [answerThreeNumberValue, setAnswerThreeNumberValue] = useState(0);
  const handleThreeNumberChange = (event) => {
    setAnswerThreeNumberValue(event.target.value);
  };

  const [answerThreeAStorageValue, setAnswerThreeAStorageValue] = useState(0);
  const handleThreeAStorageChange = (event) => {
    setAnswerThreeAStorageValue(event.target.value);
  };

  const [answerThreeADisksValue, setAnswerThreeADisksValue] = useState(0);
  const handleThreeADisksChange = (event) => {
    setAnswerThreeADisksValue(event.target.value);
  };

  const [answerFiveNumberValue, setAnswerFiveNumberValue] = useState(0);
  const handleFiveNumberChange = (event) => {
    setAnswerFiveNumberValue(event.target.value);
  };

  const [answerFiveType, setAnswerFiveType] = useState('TB')
  const handleFiveTypeChange = (event) => {
    setAnswerFiveType(event.target.value);
  }

  const [answerSix, setAnswerSix] = useState()
  const handleAnswerSixChange = (event) => {
    setAnswerSix(event.target.value)
  }

  const [answerSixDate, setAnswerSixDate] = useState()
  const handleAnswerSixDateChange = (event)=> {
    setAnswerSixDate(event.target.value)
  }

  // SET ANSWER ONE VALUE BASED ON FIRST QUESTION RESULT
  useEffect(() => {
    if (answerOne === 'All traditional HDD') {
      setAnswerOneValue(100);
    }
  }, [answerOne])


  // Perform calculation when any of the inputs change

  const pencePerkWh = 21;
  // 21p/kWh
  const gCO2PerkWh = 214;
  // 214gCO2eq/kWh
  const storageArraykWhPerYear = 10000;
  const storageDiskkWhPerYear = 83;
  const flashTotalkWhPerYearPerTB = 28;
  let PUE, storageCost, storageArrays, storageDisks

  const [baselineCosts, setBaselineCosts] = useState(0)

  useEffect(() => {
    if (answerTwo === 'Yes') {
      PUE = answerTwoSliderValue;
    }
    else if (answerTwo === 'No'){
      PUE = answerTwoA;
    }
    console.log(PUE)
    
    if (answerThree === 'Yes') {
      storageCost = answerThreeNumberValue;
      setBaselineCosts(storageCost)
    }
    else if(answerThree === 'No') {
      storageArrays = answerThreeAStorageValue;
      storageDisks = answerThreeADisksValue;
      storageCost = (pencePerkWh / 100) * PUE * ((storageArrays * storageArraykWhPerYear) + (storageDisks * storageDiskkWhPerYear));
      setBaselineCosts(storageCost.toFixed(0))
    }
  }, [answerTwo, answerTwoSliderValue, answerTwoA, answerThree, answerThreeNumberValue, answerThreeAStorageValue, answerThreeADisksValue]);

  const [flashCosts, setFlashCosts] = useState(0);
  let currentCapacity, currentCapacityTB, hddPercentage, flashCalculation

  useEffect(() => {
    if (answerTwo === 'Yes') {
      PUE = answerTwoSliderValue;
    }
    else if (answerTwo === 'No'){
      PUE = answerTwoA;
    }

    currentCapacity = answerFiveNumberValue;
    
    if (answerFiveType === 'PB') {
      currentCapacityTB = currentCapacity * 1000;
    }
    else if (answerFiveType === 'TB') {
      currentCapacityTB = currentCapacity;
    }

    hddPercentage = answerOneValue;
    flashCalculation = (hddPercentage * (pencePerkWh / 100) * PUE * (currentCapacityTB * flashTotalkWhPerYearPerTB)) / 100;
    setFlashCosts(flashCalculation.toFixed(0))
    // CalcFlashCosts = (hddPercentage * ((pencePerkWh / 100) * pue * (currentCapacityTB * flashTotalkWhPerYearPerTB))
  }, [answerOneValue, answerTwo, answerTwoSliderValue, answerTwoA, answerFiveNumberValue, answerFiveType])


  const [outputFlashSavings, setOutputFlashSavings] = useState()

  useEffect(() => {
    setOutputFlashSavings((baselineCosts - flashCosts).toFixed(0))
  }, [baselineCosts, flashCosts])


  
  const [outputKGCO2Savings, setOutputKGCO2Savings] = useState()

  useEffect(() => {
    const co2Calculation = ((baselineCosts / (pencePerkWh / 100)) - (flashCosts / (pencePerkWh / 100))) * (gCO2PerkWh / 1000)
    setOutputKGCO2Savings(co2Calculation.toFixed(0))
  }, [baselineCosts, flashCosts])


  return (
    <div className="App">
      <form className="form">

       {/* QUESTION 1 */}
        <div className = "section bg-white">
          <div className="radio-group">
            <p className="question bold">What best describes your existing
            infrastructure?</p>
            <input 
              type="radio" 
              id="answer1option1" 
              name="answer1option1" 
              value="All traditional HDD" 
              checked={answerOne === 'All traditional HDD'} 
              onChange={handleAnswerOneChange} 
            />
            <label htmlFor="answer1option1" className="label-as-button answer bold">All traditional HDD</label>

            <input 
              type="radio" 
              id="answer1option2" 
              name="answer1option2" 
              value="Mixed Flash and HDD" 
              checked={answerOne === 'Mixed Flash and HDD'} 
              onChange={handleAnswerOneChange} 
            />
            <label htmlFor="answer1option2" className="label-as-button answer bold">Mixed Flash and HDD</label>

          </div>

          {answerOne === 'Mixed Flash and HDD' && (
            <div className="slider-container">
            <p className="question slider-question bold answer">Percentage of storage on HDD:</p>
              <input
                type="range"
                min="0"
                max="100"
                value={answerOneValue}
                onChange={handleSliderOneChange}
                className="slider"
              />
              <div className="slider-value" style={{ left: `calc(${(answerOneValue - 1) / 99 * 100}% - ${((answerOneValue - 1) / 99) * 20}px + 10px)` }}>
              <p>{answerOneValue}</p>
              </div>
          </div>
          )}
        </div>

        {/* QUESTION 2 */}
        <div className = "section bg-grey">
          <div className="radio-group">
            <p className="question bold">Do you know your PUE factor?</p>
            <input 
              type="radio" 
              id="answer2option1" 
              name="answer2option1" 
              value="Yes" 
              checked={answerTwo === 'Yes'} 
              onChange={handleAnswerTwoChange} 
            />
            <label htmlFor="answer2option1" className="label-as-button answer bold">Yes</label>

            <input 
              type="radio" 
              id="answer2option2" 
              name="answer2option2" 
              value="No" 
              checked={answerTwo === 'No'} 
              onChange={handleAnswerTwoChange} 
            />
            <label htmlFor="answer2option2" className="label-as-button answer bold">No</label>

          </div>

          {answerTwo === 'Yes' && (
            <div className="slider-container">
              <p className="question slider-question bold answer">PUE factor:</p>
                <input
                  type="range"
                  id="answer2option1A"
                  min="0"
                  max="5"
                  step="0.1"
                  value={answerTwoSliderValue}
                  onChange={handleSliderTwoChange}
                  className="slider"
                />
                <div className="slider-value" style={{ left: `calc(${(answerTwoSliderValue) / 5 * 100}% - ${((answerTwoSliderValue) / 5) * 20}px + 10px)` }}>
                <p>{answerTwoSliderValue}</p>
                </div>
            </div>
          )}

          {answerTwo === 'No' && (
            <div className="radio-group">
              <p className="question bold answer">Which best describes your set-up?</p>
              <input 
                type="radio" 
                id="answer2Aoption1" 
                name="answer2Aoption1" 
                value="3.2" 
                checked={answerTwoA === '3.2'} 
                onChange={handleAnswerTwoAChange} 
              />
              <label htmlFor="answer2Aoption1" className="label-as-button answer bold">Legacy unefficient Comms Rooms/Small Data Center(s) (PUE 3.2)</label>

              <input 
                type="radio" 
                id="answer2Aoption2" 
                name="answer2Aoption2" 
                value="1.8" 
                checked={answerTwoA === '1.8'} 
                onChange={handleAnswerTwoAChange} 
              />
              <label htmlFor="answer2Aoption2" className="label-as-button answer bold">Modern dedicated Data Center(s) (PUE 1.8)</label>

              <input 
                type="radio" 
                id="answer2Aoption3" 
                name="answer2Aoption3" 
                value="1.1" 
                checked={answerTwoA === '1.1'} 
                onChange={handleAnswerTwoAChange} 
              />
              <label htmlFor="answer2Aoption3" className="label-as-button answer bold">Large co-lo/third party/most efficient own Data Centers (PUE 1.1)</label>
          </div>
          )}
        </div>
          
        {/* QUESTION 3 */}
        <div className = "section bg-white">
          <div className="radio-group">
            <p className="question bold">Do you know your existing STORAGE infrastructure costs?</p>
            <input 
              type="radio" 
              id="answer3option1" 
              name="answer3option1" 
              value="Yes" 
              checked={answerThree === 'Yes'} 
              onChange={handleAnswerThreeChange} 
            />
            <label htmlFor="answer3option1" className="label-as-button answer bold">Yes</label>

            <input 
              type="radio" 
              id="answer3option2" 
              name="answer3option2" 
              value="No" 
              checked={answerThree === 'No'} 
              onChange={handleAnswerThreeChange} 
            />
            <label htmlFor="answer3option2" className="label-as-button answer bold">No</label>

          </div>

          {answerThree === 'Yes' && (
            <div className="slider-container">
              <input
                type="number"
                name="answerThreeNumber"
                id="answerThreeNumber"
                value={answerThreeNumberValue}
                onChange={handleThreeNumberChange}
                className="textInput"
              />
              
          </div>
          )}

          {answerThree === 'No' && (
            <div>
              <p className="question bold answer">Do you know how many storage arrays and spinning disks you have?</p>
              
              <div className="slider-container pad-bottom--1">
                <p className="question slider-question bold answer">Storage Arrays</p>
                <input
                  type="range"
                    min="1"
                  max="100"
                  name="answerThreeAStorage"
                  id="answerThreeAStorage"
                  value={answerThreeAStorageValue}
                  onChange={handleThreeAStorageChange}
                  className="slider"
                />
                <div className="slider-value" style={{ left: `calc(${(answerThreeAStorageValue - 1) / 99 * 100}% - ${((answerThreeAStorageValue - 1) / 99) * 20}px + 10px)` }}>
                <p>{answerThreeAStorageValue}</p>
                  </div>
              </div>
              
              <div className="slider-container">
                <p className="question slider-question bold answer">Spinning Disks</p>
                  <input
                    type="range"
                    min="0"
                    max="50000"
                    step="500"
                    name="answerThreeADisks"
                    id="answerThreeADisks"
                    value={answerThreeADisksValue}
                    onChange={handleThreeADisksChange}
                    className="slider"
                  />
                  <div className="slider-value" style={{ left: `calc(${(answerThreeADisksValue - 1) / 49999 * 100}% - ${((answerThreeADisksValue - 1) / 49999) * 20}px + 10px)` }}>
                  <p>{answerThreeADisksValue}</p>
                  </div>
              </div>
          </div>
          )}

        </div>

        {/* QUESTION 4 */}
        <div className="section bg-grey">
          <p className="question bold">Which of the following are you looking to modernize to?</p>
          <div className="grid-2-column">
            <div>
              <input
              type="checkbox"
              id="answer4A"
              name="answer4A"
              />
              <label htmlFor='answer4A' className="answer">SAN</label>
            </div>

            <div>
              <input
              type="checkbox"
              id="answer4B"
              name="answer4B"
              />
              <label htmlFor='answer4B' className="answer">FILE</label>
            </div>
          </div>
          
          
        </div>


        {/* QUESTION 5 */}

        <div className="section bg-white">
          <p className="question bold">How much storage capacity do you have?</p>
          <div className="grid-q5">
            <input
              type="number"
              min="1"
              max="1000"
              name="answerFiveNumber"
              id="answerFiveNumber"
              value={answerFiveNumberValue}
              onChange={handleFiveNumberChange}
              className="textInput"
            />

            <select
              name="answer5Type"
              id="answer5Type"
              value={answerFiveType}
              onChange={handleFiveTypeChange}
            >
              <option value="PB">PB</option>
              <option value="TB">TB</option>
            </select>
          </div>
         

        </div>

        {/* QUESTION 6 */}
         <div className = "section bg-grey bg-img">
          <div className="radio-group">
            <p className="question bold">Do you have a NetZero policy?</p>
            <input 
              type="radio" 
              id="answer6option1" 
              name="answer6option1" 
              value="Yes" 
              checked={answerSix === 'Yes'} 
              onChange={handleAnswerSixChange} 
            />
            <label htmlFor="answer6option1" className="label-as-button answer bold">Yes</label>

            <input 
              type="radio" 
              id="answer6option2" 
              name="answer6option2" 
              value="No" 
              checked={answerSix === 'No'} 
              onChange={handleAnswerSixChange} 
            />
            <label htmlFor="answer6option2" className="label-as-button answer bold">No</label>

          </div>

          {answerSix === 'Yes' && (
            <div className="slider-container">
            <p className="question bold answer">When is your target date to achieve this?</p>
              <input
                type="text"
                value={answerSixDate}
                onChange={handleAnswerSixDateChange}
              />
              
          </div>
          )}
        </div>

        <div className="bg-purple results">
          <p>Current costs per year = £{baselineCosts ? baselineCosts : ''}</p>
          <p>Flash costs per year = £{flashCosts !== null && !isNaN(flashCosts) ? flashCosts : ''}</p>
          <p>Savings per year from implementing Flash storage = £{flashCosts > 0 ? outputFlashSavings : '' }</p>
          <p>Tonnes of emitted CO2 reduction per year from implementing flash storage = {outputKGCO2Savings !== null && !isNaN(outputKGCO2Savings)? outputKGCO2Savings : ''}</p>
        </div>
      </form>
    </div>
  );
}

export default App;
